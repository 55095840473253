export const BTN_POSITION = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

export const BUTTON_SIZE = {
  S: "S",
  M: "M",
  L: "L",
};

export const BUTTON_BACKGROUND = {
  GRADIENT: "gradient",
  ORANGE: "orange",
};

export const CONTROLS_EMAIL_COLLECT_POSITION = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right",
};

export const LINK_GENERATOR_VARIANTS = {
  ASIN: "ASIN",
  BRAND: "BRAND",
  KEYWORDS: "KEYWORDS",
  CANONICAL_URLS: "CANONICAL_URLS",
  CART: "CART",
  BUY_TOGETHER: "BUY_TOGETHER",
  TARGETED_ASIN_SEARCH: "TARGETED_ASIN_SEARCH",
  CLEAN_PRODUCT_PAGE: "CLEAN_PRODUCT_PAGE",
};

export const PAGES = {
  AMAZON_TOOLKIT: "AMAZON_TOOLKIT",
  AMAZON_SELLERS_BUNDLE: "AMAZON_SELLERS_BUNDLE",
  AMZSCOUT_VS_HELIUM_10: "AMZSCOUT_VS_HELIUM_10",
  AMZSCOUT_VS_JUNGLE_SCOUT: "AMZSCOUT_VS_JUNGLE_SCOUT",
  CN: "CN",
  FBA_FEE_CALCULATOR: "FBA_FEE_CALCULATOR",
  JP_AMAZON_SELLERS_BUNDLE: "JP_AMAZON_SELLERS_BUNDLE",
  JP_PRO: "JP_PRO",
  KEYWORD_TOOLS: "KEYWORD_TOOLS",
  KEYWORD_TRACKER: "KEYWORD_TRACKER",
  LINK_GENERATOR: "LINK_GENERATOR",
  LP_HOW_SELL: "LP_HOW_SELL",
  MAIN_PAGE: "MAIN_PAGE",
  MONTHLY_TREND_REPORT: "MONTHLY_TREND_REPORT",
  ONLINE_ARBITRAGE: "ONLINE_ARBITRAGE",
  PD: "PD",
  PRO: "PRO",
  REPORT_DEALS: "REPORT_DEALS",
  REVERSE_ASIN: "REVERSE_ASIN",
  SALES_ESTIMATOR: "SALES_ESTIMATOR",
  STOCK_STATS: "STOCK_STATS",
  PERSONAL_PRODUCT_IDEAS: "PERSONAL_PRODUCT_IDEAS",
};

export const PAGE_OPTIONS = {
  [PAGES.AMAZON_TOOLKIT]: {
    CLICK_FORM_OPERATION: "tryforfreeelementlpbundleoa",
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amazon-dropshipping-arbit/edkgpjhfpfpgkohafpaliolcfnijnibh",
    SOFTWARE: "OA_EXT",
  },
  [PAGES.AMAZON_SELLERS_BUNDLE]: {
    CLICK_FORM_OPERATION: "waforfreeelement",
    REDIRECTED_URL: "/app/",
    SOFTWARE: "WEB",
  },
  [PAGES.PERSONAL_PRODUCT_IDEAS]: {
    CLICK_FORM_OPERATION: "lppersonalproductideas",
    REDIRECTED_URL: "/app/#/reports/customideas",
    SOFTWARE: "WEB",
  },
  [PAGES.AMZSCOUT_VS_HELIUM_10]: {
    CLICK_FORM_OPERATION: "helium10",
    REDIRECTED_URL: "/app/#/database",
    SOFTWARE: "WEB",
  },
  [PAGES.AMZSCOUT_VS_JUNGLE_SCOUT]: {
    CLICK_FORM_OPERATION: "junglescout",
    REDIRECTED_URL: "/app/#/database",
    SOFTWARE: "WEB",
  },
  [PAGES.CN]: {
    CLICK_FORM_TYPE: 25,
    INTRO_REDIRECTED_URL: {
      EN: "/app/#/course",
      CN: "/cn/wa-vpn/",
    },
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amzscout-pro/njopapoodmifmcogpingplfphojnfeea?с=china",
    SOFTWARE: "WEB",
  },
  [PAGES.FBA_FEE_CALCULATOR]: {
    CLICK_FORM_TYPE: 15,
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amazon-fba-calculator-fre/dkgjopcolgcafhnicdahjemapkniikeh?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp",
    SOFTWARE: "CALC_EXT",
  },
  [PAGES.JP_AMAZON_SELLERS_BUNDLE]: {
    CLICK_FORM_OPERATION: "sellersbundlesubscriptionjp",
    REDIRECTED_URL: "/app/#/database",
    SOFTWARE: "WEB",
  },
  [PAGES.JP_PRO]: {
    CLICK_FORM_OPERATION: "proforfreeelementlpprojp",
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amzscout-pro/njopapoodmifmcogpingplfphojnfeea",
    SOFTWARE: "SCOUT_EXT_PRO",
  },
  [PAGES.KEYWORD_TOOLS]: {
    CLICK_FORM_TYPE: 7,
    REDIRECTED_URL: "/app/#/product-keywords",
    SOFTWARE: "WEB",
  },
  [PAGES.KEYWORD_TRACKER]: {
    CLICK_FORM_OPERATION: "keywordtrackerforfreelp",
    REDIRECTED_URL: "/app/#/keyword-tracker",
    SOFTWARE: "KW_TRACKER_EXT",
  },
  [PAGES.LINK_GENERATOR]: {
    CLICK_FORM_TYPE: 22,
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=super_url_lp",
    SOFTWARE: "SCOUT_EXT_PRO",
  },
  [PAGES.LP_HOW_SELL]: {
    CLICK_FORM_TYPE: 18,
    REDIRECTED_URL: "/app/#/course",
    SOFTWARE: "WEB",
  },
  [PAGES.MAIN_PAGE]: {
    CLICK_FORM_TYPE: 1,
    REDIRECTED_URL: { DESKTOP: "/app/#/database", MOBILE: "/app/#/course" },
    SOFTWARE: "WEB",
    FOOTER_FORM_TITLE:
      "Try the most accurate tool for product research, and find unsaturated niches and profitable products",
  },
  [PAGES.MONTHLY_TREND_REPORT]: {
    CLICK_FORM_OPERATION: "getmonthlyreportcrosspromocompany",
    REDIRECTED_URL: "",
    SOFTWARE: "LANDING",
  },
  [PAGES.ONLINE_ARBITRAGE]: {
    CLICK_FORM_OPERATION: "tryforfreeelementlpoa",
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amazon-dropshipping-arbit/edkgpjhfpfpgkohafpaliolcfnijnibh",
    SOFTWARE: "OA_EXT",
  },
  [PAGES.PD]: {
    CLICK_FORM_TYPE: 16,
    REDIRECTED_URL: "/app/#/database",
    CLICK_FORM_OPERATION: "proforfreeelementpdlanding",
    SOFTWARE: "WEB",
  },
  [PAGES.PRO]: {
    CLICK_FORM_TYPE: 17,
    CLICK_FORM_OPERATION: "proforfreeelementlppro",
    REDIRECTED_URL:
      "https://chrome.google.com/webstore/detail/amazon-product-finder-amz/njopapoodmifmcogpingplfphojnfeea?utm_source=site&utm_medium=amzscout&utm_campaign=pro_lp",
    SOFTWARE: "SCOUT_EXT_PRO",
  },
  [PAGES.REPORT_DEALS]: {
    CLICK_FORM_OPERATION: "getthesamplereportlpreportdeals",
    REDIRECTED_URL: "",
    SOFTWARE: "LANDING",
  },
  [PAGES.REVERSE_ASIN]: {
    CLICK_FORM_TYPE: 21,
    REDIRECTED_URL: "/app/#/product-keywords",
    SOFTWARE: "WEB",
  },
  [PAGES.SALES_ESTIMATOR]: {
    CLICK_ESTIMATOR_FORM_OPERATION: "getfullaccesstosalesestimator",
    CLICK_PRO_FORM_OPERATION: "proforfreeelementcentersalesestimator",
    REDIRECTED_URL: "/app/#/reports/reports",
    SOFTWARE: "WEB",
  },
  [PAGES.STOCK_STATS]: {
    CLICK_FORM_OPERATION: "proforfreeelementlpss",
    REDIRECTED_URL:
      "https://chromewebstore.google.com/detail/amzscout-stock-stats-amaz/liobflkelkokkacdemhmgkbpefgaekkm?utm_source=site&utm_medium=amzscout&utm_campaign=stock_stats_lp",
    SOFTWARE: "WEB",
  },
};

export const FOOTER_EMAIL_COLLECT_DATA = {
  OPERATION: "proforfreeelementfooter",
  DEFAULT_TITLE:
    "Try the most accurate tool for product research, and find unsaturated niches and profitable products",
};

export const PAGE_CLASS_NAMES = {
  PgA: "about",
  PgAKT: "amazon-keyword-tools",
  PgAP: "affiliate-program",
  PgASB: "amazon-sellers-bundle",
  PgASU: "amazon-super-urls",
  PgAT: "amazon-toolkit",
  PgAVH: "amzscout-vs-helium-10",
  PgAVJS: "amzscout-vs-jungle-scout",
  PgAuth: "authorization",
  PgCI: "cn/instructions",
  PgCMain: "cn",
  PgCP: "courses-page",
  PgCPV: "cn/pro-vpn",
  PgCPr: "cn/pricing",
  PgCW: "cn/withoutvpn",
  PgCWV: "cn/wa-vpn",
  PgDMain: "de",
  PgDP: "diversity-policy",
  PgEM: "/",
  PgEP: "iframe/enterprise-paywall",
  PgFFC: "fba-fee-calculator",
  PgGT: "goto",
  PgI: "instruction",
  PgIPR: "instruction-product-report",
  PgIWF: "iframe/wa-feedback",
  PgIWHPBP: "iframe/wa-hot-products-bundle-paywall",
  PgIWHPP: "iframe/wa-hot-products-paywall",
  PgIWIRP: "iframe/wa-individual-report-paywall",
  PgIWLBP: "iframe/wa-listing-builder-paywall",
  PgIWOAP: "iframe/wa-oa-addon-paywall",
  PgIWP: "iframe/wa-paywall",
  PgJASB: "jp/amazon-sellers-bundle",
  PgJP: "jp/pro",
  PgJPr: "ja/pricing",
  PgKT: "keyword-tracker",
  PgLHTSOA: "lp/how-to-sell-on-amazon",
  PgMTR: "monthly-trend-report",
  PgOA: "online-arbitrage",
  PgOI: "oa-instruction",
  PgP: "pro",
  PgPD: "product-database",
  PgPEU: "pw/extension-upgrade",
  PgPP: "privacy-policy",
  PgPPI: "personal-product-ideas",
  PgPr: "pricing",
  PgR: "rate",
  PgRAL: "reverse-asin-lookup",
  PgRD: "report-deals",
  PgRP: "review-page",
  PgRU: "request-upgrade",
  PgSE: "sales-estimator",
  PgSK: "seller-kit",
  PgSKR: "seller-kit-reselling",
  PgSS: "stock-stats",
  PgSU: "safety-using",
  PgT: "testimonials",
  PgTOU: "terms-of-use",
  PgU: "services/updater",
  PgUP: "tools/uploader",
  PgVDP: "vulnerabilities-disclosure-policy",
  PgWNA: "why-not-available",
  PgWQ: "w-questionnaire",
  TeCT: "courses-templates",
  TeCuT: "customizable-template",
  TeECT: "ext-pricing-template",
  TeEQ: "ext-questionnaire",
  TeMT: "masterclasses-templates",
  TeUDT: "university-details-templates",
  TeULT: "university-list-templates",
  TeWT: "webinars-templates",
};
export const GLOBAL_COMPONENT_CLASS_NAMES = {
  "GC-B": "common/Brands",
  "GC-CR": "common/ChromeRating",
  "GC-PB": "common/PartnerBrand",
  "GC-Q": "common/Questionnaire",
  "GC-RN": "common/RatingNow",
  "GC-SB": "common/SurveyButton",
  "GC-SD": "common/SimpleDeals",
  "GC-TS": "common/TestimonialsSection",
  "GC-VT": "common/VideoTestimonials",
  "GC-WNB": "WebinarNativeBanner",
  "GCF-FI": "common/Forms/FormIntro",
  "GCF-FP": "common/Forms/FormPage",
  "GCL-ES": "Layout/EmptySpace",
  "GCL-F": "Layout/Footer",
  "GCL-H": "Layout/Header",
  "GCL-HC": "Layout/HeaderCn",
  "GP-C": "Paywalls/Customizable",
  "GPM-PM": "Paywalls/Modal/PricingModal",
};
export const UI_DESIGN_COMPONENT_CLASS_NAMES = {
  "UI-C": "UI/Container",
};

export const COPY_RIGHT = {
  ALL_RIGHTS_RESERVED: `© AMZScout. All rights reserved 2016-${process.env.YEAR}`,
  ADDRESSES: {
    PHILADELPHIA:
      "AMZScout Corp., 1735 Market Street, Suite 3750, Philadelphia, Pennsylvania 19103",
    DUBAI:
      "Growthco DMCC, Unit No: BA402, DMCC Business Centre, Level No 1, Jewellery & Gemplex 3, Dubai, UAE",
  },
};
export const TEXT_ENTER_YOUR_EMAIL = {
  cn: "请输入您的电子邮件地址",
  de: "Geben Sie Ihre E-Mail ein",
  en: "Please enter your email",
  ja: "メールアドレスを入力してください。",
};
export const TEXT_ADDRESS_IS_INVALID = {
  cn: "您的电子邮件地址有误",
  de: "E-Mail ungültig",
  en: "Your email address is invalid",
  ja: "メールアドレスは無効で",
};
export const TEXT_PLEASE_TRY_AGAIN = {
  cn: "出现了一些错误。请重试。",
  de: "Etw. ging schief. Bitte erneuern Sie.",
  en: "Something went wrong. Please try again.",
  ja: "エラーが発生していました。再試行してください。",
};
export const ANALYTICS_CATEGORY = {
  AMAZON_SELLERS_BUNDLE: "LANDING|amazon-sellers-bundle",
  PRICING: "LANDING|Pricing"
};